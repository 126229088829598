<template xmlns:src="http://www.w3.org/1999/xhtml" xmlns:v-bind="http://www.w3.org/1999/xhtml"
          xmlns:v-on="http://www.w3.org/1999/xhtml">
    <div class="row left-align vert-offset-top-1">
        <div class="col s12 l10 offset-l1">
            <div class="card col s12 vert-offset-top-1 padding-top-half">
                <div class="col s12 padding-left-half padding-right-half padding-top-half">
                    <div class="row vert-offset-bottom-half">
                        <div class="col s12 vert-offset-bottom-half border-bottom-1 grey-border border-lighten-3">
                            <div class="col s12 no-padding font-12 grey-text text-darken-2 font-bold">
                                Which Product/Service are you looking for?({{ tag_selected_list.length }})
                            </div>

                            <div class="col s12 input-field vert-offset-top-1">
                                <input id="search_tags" type="search" v-model="tag_search_key" required autocomplete="off" placeholder="Type Keyword here to search for it">
                                <label for="search_tags"><i class="material-icons black-text">search</i></label>

                                <div class="col s12 no-padding grey-text text-darken-2 padding-bottom-half" v-if="(tag_search_result === null || tag_search_result.length <= 0) && new_search === true && tag_search_key !== null && tag_search_key.length > 0">
                                    No matching keyword found.
                                    <i class="material-icons tiny black-text right clickable"
                                       @click="resetSearchResult()"
                                    >close</i>
                                </div>

                                <div class="col s12 no-padding" v-if="tag_search_result && tag_search_result.length > 0">
                                    <ul class="collection font-12 vert-offset-bottom-half">
                                        <li class="collection-item col s12 left-align padding-bottom-half padding-top-half vert-offset-bottom-0 clickable green lighten-4 black-text border-top-1 grey-border border-lighten-1"
                                            v-for="(item, index) in tag_search_result"
                                            :key="index"
                                            @click="selectTag(item)"
                                        >
                                            {{item.name}}
                                        </li>
                                    </ul>
                                </div>
                                <div class="col s12 no-padding" v-if="tag_selected_list.length > 0">
                                    <span class="padding-bottom-half padding-top-half vert-offset-bottom-0 padding-left-1 padding-right-1 vert-offset-right-half clickable grey lighten-2 homeboy-charcoal-black-text rounded-5"
                                          v-for="(item, index) in tag_selected_list"
                                          :key="index"
                                    >
                                            {{item.name}}
                                            <i class="material-icons tiny black-text"
                                               @click="deSelectTag(index)"
                                            >close</i>
                                        </span>
                                </div>
                            </div>
                        </div>
                        <div class="col s12 grey-text text-darken-2 padding-right-0 vert-offset-top-half padding-top-half padding-bottom-1 border-bottom-1 grey-border border-lighten-3">
                            <span class="switch-label">Near My Location</span>
                            <span class="switch">
                                <label>
                                    <input type="checkbox" v-model="near_me" ref="near_me" :disabled="SearchbyLocationOnly">
                                    <span class="lever"></span>
                                    Search by Location
                                </label>
                            </span>

                        </div>
                    </div>
                    <div class="row vert-offset-bottom-half">
                        <nav class="vert-offset-bottom-1" v-if="near_me">
                            <div class="nav-wrapper white lighten-2">
                                <form @submit.prevent="searchSupplier">
                                    <div class="input-field">
                                        <input id="search" type="search" v-model="search_input" required autocomplete="off" placeholder="Type Location Here and press Enter/Return/Go">
                                        <label class="label-icon" for="search"><i class="material-icons black-text">search</i></label>
                                            <i @click.prevent="removeSearchInput" class="material-icons black-text search-close-btn">close</i>
                                    </div>
                                </form>
                            </div>
                        </nav>
                        <div class="col s12" v-if="picked_location">
                            <span
                                    class="padding-bottom-half padding-top-half vert-offset-bottom-0 padding-left-1 padding-right-1 vert-offset-right-half clickable grey lighten-2 homeboy-charcoal-black-text rounded-5"
                            >
                                            {{picked_location.name}}
                                        </span>
                        </div>
                        <div class="col s12 font-10 blue-text text-darken-4" v-if="!locationSelected && search_input.length >= 2">
                            <span v-if="districtsExist || citiesExist || wardsExist">Please click on any of the following location(s) to Select a Location</span>
                            <span v-else>No Location found</span>
                        </div>

                        <div class="col s12" v-if="!locationSelected">
                            <ul class="collection with-header font-12 vert-offset-bottom-half" v-if="districtsExist">
                                <li class="collection-header padding-bottom-half padding-top-half vert-offset-bottom-0">
                                    District
                                </li>
                                <li class="collection-item col s12 left-align padding-bottom-half padding-top-half vert-offset-bottom-0 clickable"
                                    v-for="(item, index) in search_results['district']"
                                    :key="index"
                                    v-on:click.prevent="selectDistrict(item)"
                                >
                                    <div class="col s12 no-padding"

                                    >
                                        <a class="homeboy-charcoal-black-text text-darken-2 underline" v-on:click.prevent="">{{item.name}}</a>
                                    </div>
                                </li>
                            </ul>
                            <ul class="collection with-header font-12 vert-offset-bottom-half" v-if="citiesExist">
                                <li class="collection-header padding-bottom-half padding-top-half vert-offset-bottom-0">
                                    Cities/Towns
                                </li>
                                <li class="collection-item col s12 left-align padding-bottom-half padding-top-half vert-offset-bottom-0 clickable"
                                    v-for="(item, index) in search_results['city']"
                                    :key="index"
                                    @click.prevent="selectTown(item)"
                                >
                                    <div class="col s12 no-padding"
                                    >
                                        <a class="homeboy-charcoal-black-text text-darken-2 underline" v-on:click.prevent="">{{item.name}}</a>
                                    </div>
                                </li>
                            </ul>
                            <ul class="collection with-header font-12 vert-offset-bottom-half" v-if="wardsExist">
                                <li class="collection-header padding-bottom-half padding-top-half vert-offset-bottom-0">
                                    Locations
                                </li>
                                <li class="collection-item col s12 left-align padding-bottom-half padding-top-half vert-offset-bottom-0 clickable"
                                    v-for="(item, index) in search_results['ward']"
                                    :key="index"
                                    @click.prevent="selectWard(item)"
                                >
                                    <div class="col s12 no-padding"
                                    >
                                        <a class="homeboy-charcoal-black-text text-darken-2 underline" @click.prevent="">{{item.name}}</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="row vert-offset-bottom-half grey-border border-lighten-3 padding-top-half padding-bottom-half padding-left-half padding-right-half border-1" v-if="showFilters">
                            <div class="col s12">
                                <div class="col s12 no-padding right-align vert-offset-bottom-half">
                                    <span class="left font-bold font-16">Filters</span>
                                    <a class="right btn btn-tiny homeboy-charcoal-black homeboy-gold-text text-darken-2 padding-default" @click.prevent="popupFilterSelect">
                                        <i class="material-icons tiny">close</i>
                                    </a>
                                </div>
                                <div class="col s12 border-bottom-1 grey-border border-lighten-2 padding-top-half vert-offset-bottom-half">
                                    <div class="col s12 font-bold border-bottom-1 grey-border border-lighten-2 padding-bottom-half">
                                        <span>Discount (%)</span>
                                    </div>
                                    <div class="col s12 vert-offset-bottom-1 input-field">
                                        <p v-for="(item, index) in discounts"
                                           :key="index">
                                            <label>
                                                <input type="checkbox" :value="item"
                                                       :key="index" v-model="discount"/>
                                                <span v-html="item" class="bold homeboy-charcoal-black-text"></span>
                                            </label>
                                        </p>


                                    </div>
                                </div>

                                <div class="col s12 border-bottom-1 grey-border border-lighten-2 padding-top-half vert-offset-bottom-half">
                                    <form action="#">
                                        <p class="range-field">

                                            <label class="font-12 grey-text text-darken-2" for="distance">Distance from center</label><span class="blue-text darken-4 font-12">({{distance}} km)</span>
                                            <input type="range" id="distance" min="1" step="1" max="20" v-model="distance" />

                                        </p>
                                    </form>

                                </div>

                            </div>

                        </div>
                        <div class="col s12 no-padding">
                            <a
                                    v-show="!showList && searchTriggered && !showFilters"
                                    @click.prevent="toggleList"
                                    class="homeboy-charcoal-black-text vert-offset-left-half padding-default clickable"
                                    title="List"
                            >
                                <i class="material-icons">list</i>

                            </a>
                            <a
                                    v-show="showList && searchTriggered && !showFilters && foundMappedLocations"
                                    @click.prevent="toggleList"
                                    class="homeboy-charcoal-black-text vert-offset-left-half padding-default clickable"
                                    title="Map"
                            >
                                <i class="material-icons">map</i>
                            </a>

                            <a v-show="canGeoLocate && geoSearch"
                                    @click.prevent="locateMe"
                                    class="right homeboy-charcoal-black-text vert-offset-left-half padding-default clickable"
                               title="GO"
                            >
                                <i class="material-icons">my_location</i>
                            </a>

                            <a
                                    v-show="!showFilters"
                                    class="right homeboy-charcoal-black-text vert-offset-left-half padding-default clickable"
                                    title="Filter"
                                    @click.prevent="popupFilterSelect"
                            >
                                <i class="material-icons">filter_list</i>
                            </a>
                            <a
                                    v-show="formUpdated && searchTriggered"
                                    class="right homeboy-charcoal-black-text vert-offset-left-half padding-default clickable"
                                    @click.prevent="updateFilters"
                                    title="Refresh"
                            >
                                <i class="material-icons">refresh</i>
                            </a>

                        </div>
                    </div>



                    <div class="col s12 font-10 no-padding" v-show="searchTriggered && !showFilters">
                        <div class="col s12 padding-top-half padding-bottom-half grey-text text-darken-2">
                            <div class="col s12 no-padding">
                                <div class="col s6 vert-offset-top-half no-padding">
                                    <span class="font-bold homeboy-charcoal-black-text">{{ total }}</span> Search Result<span v-show="total>0">s</span>
                                </div>
                                <div class="col s6 vert-offset-top-half no-padding right-align">Near
                                    <span class="font-bold homeboy-charcoal-black-text" v-if="near_me && selected_location">{{ selected_location.name }}
                                </span>
                                    <span class="font-bold homeboy-charcoal-black-text" v-else>Me</span>
                                </div>
                            </div>

                            <div class="col s12 no-padding">

                                <span v-if="selectedTags">
                                    Tag<span v-show="tag_selected_list.length >1">s</span>({{ tag_selected_list.length }}): <span
                                        v-for="(_item,_index) in tag_selected_list"
                                        :key="_index"
                                        class="vert-offset-right-half underline font-bold homeboy-charcoal-black-text"
                                >
                                            {{ _item.name }}
                                        </span>
                                </span>

                               <!-- </div>-->
                            </div>


                        </div>

                    </div>
                </div>

            </div>
        </div>
        <div class="col s12 l10 offset-l1 font-12 padding-bottom-1" v-show="foundLocations">
            <div
                    v-show="showList"
                    class="col s12 no-padding"
            >

                <search-result-element-component :item="item" v-for="(item, index) in location"
                                                 :key="index"
                ></search-result-element-component>
                <div class="col s12 font-10 no-padding center">
                    <button class="btn btn-tiny grey lighten-2 homeboy-charcoal-black-text" v-if="showNext" @click.prevent="loadNext">more</button>
                </div>
            </div>

        </div>
        <div class="col s12 no-padding" v-if="showMap && foundMappedLocations">
            <div
                    class="col s12 no-padding"
                    v-show="!showList"
            >

                <map-component :latlon="latlon" :center="center"></map-component>
            </div>
        </div>
    </div>
</template>
<script>
    // @ is an alias to /src
    import {mapState} from "vuex"
    import http from "../../http-common"
    import MapComponent from "@/components/maps/MapComponent.vue"
    import SearchResultElementComponent from "@/components/SearchResultElementComponent.vue"

    export default {
        name: "SupplierSearch",
        components:{
            MapComponent,SearchResultElementComponent
        },
        data() {
            return {
                picked_location: null,
                organisation_logo_url: "",
                myLocation: null,
                selected_location: null,
                location_type: null,
                instance: null,
                search_input:"",
                search_timer: null,
                locationSelected:false,
                search_results:null,
                location:null,
                latlon:[],
                center:null,
                activeClass: "material-icons green-text text-darken-2 left tiny",
                inactiveClass: "material-icons green-text text-darken-2 left tiny",
                discounts:[],
                showFilter:false,
                discount:[],
                selectedChips:[],
                distance:10,
                tags: [],
                searchTriggered: false,
                formUpdated: false,
                total: 0,
                search: {
                    city: null,
                    district: null,
                    ward: null,
                    pagination:null,
                    links:null,
                    base_url:"/search/supplier",
                    location_base_url: "/search/supplier/location",

                },
                tag_search_key: "",
                new_search: false,
                tag_search_result: null,
                tag_selected_list: [],
                defaultImage: require("@/assets/img/homeboy_banner.png"),
                near_me: false,
                showList: true,
                canGeoLocate: false

            }
        },
        created () {
            this.getSupplierDiscounts();
            this.getTags();
            if(typeof this.$baseUrl !== "undefined" && this.$baseUrl !== null && this.$baseUrl.length >= 0)
            {
                this.organisation_logo_url = this.$baseUrl+ "organisations/logo/";
            }
        },
        mounted()
        {
            this.loadPage();
            this.checkGeoLocation();
        },
        computed: {
            ...mapState({
                customer:state=>state.customers.customerInfo,
                user:state=>state.users.organisation_user
            }),

            SearchbyLocationOnly:function(){
                return (!this.canGeoLocate && this.near_me);
            },
            geoSearch: function()
            {
                return (this.near_me === false);
            },
            showMap:function () {
                return this.locationSelected
            },
            districtsExist:function () {

                if((this.search_results === null))
                    return false;

                return (this.search_results !== null &&  typeof this.search_results['district'] !== "undefined" && this.search_results.length > 0);
                //console.log(JSON.stringify(this.search_results));
            },
            wardsExist:function () {
                if((this.search_results === null))
                    return false;

                return (this.search_results !== null && typeof this.search_results['ward'] !== "undefined" && this.search_results['ward'].length > 0);

            },
            citiesExist:function () {
                if((this.search_results === null))
                    return false;

               return (this.search_results !== null && typeof this.search_results['city'] !== "undefined" && this.search_results['city'].length > 0);
            },
            showFilters:function(){
                return this.showFilter
            },
            selectedDistrict: function()
            {
                return (typeof this.search.district !== "undefined" && this.search.district !== null);
            },
            selectedCity: function()
            {
                return (typeof this.search.city !== "undefined" && this.search.city !== null);
            },
            selectedWard: function()
            {
                return (typeof this.search.ward !== "undefined" && this.search.ward !== null);
            },
            foundLocations: function()
            {
                return (typeof this.location !== "undefined" && this.location !== null && this.location.length > 0);
            },
            showNext:function ()
            {
                /*if(this.formUpdated == true)
                    return false;*/

                if(typeof this.location === "undefined" || this.location === null || this.location.length <= 0)
                {
                    return false;
                }
                if(typeof this.search.links === "undefined" || this.search.links === null)
                {
                    return false;
                }

                if(typeof this.search.links.next === "undefined" || this.search.links.next === null)
                {
                    return false;
                }
                return true;
            },
            foundMappedLocations: function()
            {
                return (typeof this.latlon !== "undefined" && this.latlon !== null && this.latlon.length > 0);
            },
            selectedLocation: function()
            {
                return (this.search.district || this.search.city || this.search.ward);
            },
            selectedTags: function()
            {
                if(typeof this.tag_selected_list === "undefined" || this.tag_selected_list === null || this.tag_selected_list.length <= 0)
                    return false;
                return true;
            }
        },
        methods : {
            loadPage: function()
            {
                this.$setPageHeader({
                        title: "Find a HomeBoy",
                        icon: "chevron_left",
                        url: "/customer/home"
                    });
                this.loadMenu();
            },

            loadMenu: function()
            {
                let _arrMenu = [];
                this.$store.dispatch('COMMIT_SET_MENU_LINKS', _arrMenu);
            },
            deSelectTag(_index)
            {
                if(typeof this.tag_selected_list !== "undefined" && this.tag_selected_list !== null && this.tag_selected_list.length > _index && _index >= 0)
                {
                    this.tag_selected_list.splice(_index, 1);
                }
            },
            selectTag(_item)
            {
                this.tag_selected_list.push(_item);
                this.tag_search_result = null;
                this.tag_search_key = "";
            },
            resetSearchResult()
            {
                this.tag_search_result = null;
                this.new_search = false;
            },
            removeSearchInput()
            {
                this.search_input = "";
            },
            imgUrlAlt(event) {
                event.target.src = this.defaultImage;
            },
            getLogo: function(_item)
            {
               return this.organisation_logo_url+_item.organisation;
            },
            toggleList: function()
            {
                this.showList = !this.showList;
            },
            isValidLatitude: function(_value)
            {
                return (typeof _value !== "undefined" && _value !== null && !isNaN(parseFloat(_value))&& _value >= -90 && _value <= 90);
            },
            isValidLongitude: function(_value)
            {
                return (typeof _value !== "undefined" && _value !== null && !isNaN(parseFloat(_value))&& _value >= -180 && _value <= 180);
            },
            selectDistrict: function(_item)
            {
                this.location_type = "District";
                this.search.district = _item;
                this.search.city = null;
                this.search.ward = null;
                this.picked_location = _item;
                if((!this.isValidLatitude(_item.latitude) || !this.isValidLongitude(_item.longitude)) && this.near_me === false)
                {
                    this.near_me = true;
                }
                this.searchCoordinates(_item);
            },
            selectTown: function(_item)
            {
                this.location_type = "Town";
                this.search.ward = null;
                this.search.city = _item;
                this.picked_location = _item;
                if(typeof _item.district.data !== "undefined" && _item.district.data !== null)
                {
                    this.search.district = _item.district.data;
                }

                if((!this.isValidLatitude(_item.latitude) || !this.isValidLongitude(_item.longitude)) && this.near_me === false)
                {
                    this.near_me = true;
                }
                this.searchCoordinates(_item);
            },
            selectWard: function(_item)
            {
                this.location_type = "Ward";
                this.search.ward = _item;
                this.picked_location = _item;
                if(typeof _item.city.data !== "undefined" && _item.city.data !== null)
                {
                    this.search.city = _item.city.data;
                }
                if(typeof _item.district.data !== "undefined" && _item.district.data !== null)
                {
                    this.search.district = _item.district.data;
                }
                if((!this.isValidLatitude(_item.latitude) || !this.isValidLongitude(_item.longitude)) && this.near_me === false)
                {
                    this.near_me = true;
                }
                this.searchCoordinates(_item);
            },
            updateFilters: function()
            {
                this.popupFilterSelect();
                if(typeof this.center === "undefined" || this.center === null)
                {
                    this.$store.dispatch('GET_MESSAGES', ["Please provide a search location."]);
                    return false;
                }
                this.searchLocation(this.center.lat, this.center.lng);
            },
            searchCoordinates: function (item, url)
            {
                this.selected_location = item;

                this.center = {
                    lat: (this.$isValidLatitude(this.selected_location.latitude)?parseFloat(this.selected_location.latitude):null),
                    lng: (this.$isValidLatitude(this.selected_location.longitude)?parseFloat(this.selected_location.longitude):null)
                };

                this.searchLocation(this.selected_location.latitude,this.selected_location.longitude, url);
            },
            searchLocation: function(_latitude, _longitude, url)
            {
                if(typeof url === "undefined" || url === null || this.formUpdated == true)
                {
                    if(!this.near_me)
                        url = this.search.base_url;
                    else
                        url = this.search.location_base_url;

                    this.location = new Array();
                }
                this.$store.dispatch('START_PROCESSING');
                return new Promise((resolve) => {
                    http({
                        url: url,
                        data: {
                            longitude: _longitude,
                            latitude: _latitude,
                            radius: this.distance,
                            discounts: this.discount,
                            tags: this.selectedChips,
                            district: (this.selectedDistrict?this.search.district.id:null),
                            city: (this.selectedCity?this.search.city.id:null),
                            ward: (this.selectedWard?this.search.ward.id:null),
                            source: this.$source

                        },
                        method: 'POST',
                        timeout: 30000
                    })
                        .then(resp => {

                            this.$store.dispatch('STOP_PROCESSING');

                            if(typeof resp.data.data !== "undefined" && resp.data.data !== null && resp.data.data.length > 0)
                            {
                                for(let _k=0;_k<resp.data.data.length;_k++)
                                {
                                    this.location.push(resp.data.data[_k]);
                                }
                            }
                            this.showFilter = false;
                            this.locationSelected = true;
                            this.searchTriggered = true;

                            this.total = resp.data.pagination.total;
                            this.formUpdated = false;
                            this.search.links = resp.data.links;
                            this.search.pagination = resp.data.pagination;

                            // you have your token, now log in your user :)

                            resolve(resp)
                        })
                        .catch((response) => {
                            this.$store.dispatch('STOP_PROCESSING');
                            this.$store.dispatch('GET_MESSAGES', response.data.messages);
                            //reject(err)
                        })
                });
            },
            onPageChanged: function (page) {
                this.current_page = page
            },
            searchSupplier: function () {

                if(typeof this.search_input === "undefined" || this.search_input === null || this.search_input.length < 2)
                    return false;

                this.locationSelected = false;

                this.$store.dispatch('START_PROCESSING');
                return new Promise((resolve) => {
                    http({
                        url: "/location/search",
                        data: {
                            name: this.search_input,
                            source: this.$source
                        },
                        method: 'POST',
                        timeout: 30000
                    })
                        .then(resp => {

                            this.$store.dispatch('STOP_PROCESSING');
                            this.search_results = resp.data.data;
                            // you have your token, now log in your user :)

                            resolve(resp)
                        })
                        .catch((response) => {
                            this.$store.dispatch('STOP_PROCESSING');
                            this.$store.dispatch('GET_MESSAGES', response.data.messages);
                            //reject(err)
                        })
                })
            },
            searchTags: function () {

                if(typeof this.tag_search_key === "undefined" || this.tag_search_key === null || this.tag_search_key.length < 2)
                    return false;

                this.resetSearchResult();

                this.$store.dispatch('START_PROCESSING');
                return new Promise((resolve) => {
                    http({
                        url: "/supplier/tags/search",
                        data: {
                            name: this.tag_search_key,
                            exclude: this.selectedChips,
                            source: this.$source
                        },
                        method: 'POST',
                        timeout: 30000
                    })
                        .then(resp => {

                            this.$store.dispatch('STOP_PROCESSING');

                            this.tag_search_result = resp.data.data;
                            this.new_search = true;

                            // you have your token, now log in your user :)

                            resolve(resp)
                        })
                        .catch((response) => {
                            this.$store.dispatch('STOP_PROCESSING');
                            this.$store.dispatch('GET_MESSAGES', response.data.messages);
                            //reject(err)
                        })
                })
            },
            convertDistance: function(_item)
            {
                let _distance;
                let _isKm = true;
                if(_item.distance < 1)
                {
                    _isKm = false;
                    _distance = parseFloat(_item.distance).toFixed(3) * 1000;
                }
                else
                {
                    _distance = parseFloat(_item.distance).toFixed(2);
                }


                return _distance + (_isKm?"Km":"m");
            },

            loadNext: function ()
            {
                if (this.showNext === true)
                {
                    this.searchLocation(this.center.lat, this.center.lng, this.search.links.next);
                }
            },

            popupFilterSelect: function () {
                this.showFilter = !this.showFilter;
            },
            getSupplierDiscounts: function () {
                this.$store.dispatch('START_PROCESSING');
                return new Promise((resolve) => {
                    http({
                        url: "l2l/suppliers/registration/form",
                        method: 'POST',
                        timeout: 30000
                    })
                        .then(resp => {

                            this.$store.dispatch('STOP_PROCESSING');

                            this.discounts = resp.data.data.discounts;

                            // you have your token, now log in your user :)

                            resolve(resp)
                        })
                        .catch((response) => {
                            this.$store.dispatch('STOP_PROCESSING');
                            this.$store.dispatch('GET_MESSAGES', response.data.messages);
                            //reject(err)
                        })
                })
            },
            getTags: function () {
                this.$store.dispatch('START_PROCESSING');
                return new Promise((resolve) => {
                    http({
                        url: "l2l/customer/tags/list",
                        method: 'POST',
                        timeout: 30000
                    })
                        .then(resp => {

                            this.$store.dispatch('STOP_PROCESSING');

                            this.tags = resp.data.data;

                            // you have your token, now log in your user :)

                            resolve(resp)
                        })
                        .catch((response) => {
                            this.$store.dispatch('STOP_PROCESSING');
                            this.$store.dispatch('GET_MESSAGES', response.data.messages);
                            //reject(err)
                        })
                })
            },
           /* chipData: function (data) {

                this.selectedChips = new Array();
                console.log("Data: " + JSON.stringify(data));

                ///this.selectedChips = data;

                for (let i = 0; i < data.length; i++) {
                    var _arrChips = this.tags.filter(function (_item) {
                        return (_item.name == data[i].tag );
                    });

                    if (_arrChips.length > 0) {
                        this.selectedChips.push(_arrChips[0].key);
                    }
                }
                console.log("Selected Chips: " + JSON.stringify(this.selectedChips));

                //return data;
            },*/
            updateSupplierTags: function (_key) {
                this.$store.dispatch('START_PROCESSING');
                return new Promise((resolve) => {
                    http({
                        url: "l2l/suppliers/tags/update",
                        method: 'POST',
                        timeout: 30000,
                        data: {
                            supplier: _key,
                            source: this.$source
                        }
                    })
                        .then(resp => {

                            this.$store.dispatch('STOP_PROCESSING');

                            this.tags = resp.data.data;

                            // you have your token, now log in your user :)

                            resolve(resp)
                        })
                        .catch((response) => {
                            this.$store.dispatch('STOP_PROCESSING');
                            this.$store.dispatch('GET_MESSAGES', response.data.messages);
                            //reject(err)
                        })
                })
            },
            checkGeoLocation: function()
            {
                if (window.navigator.geolocation)
                {
                    this.canGeoLocate = true;
                } else {
                    this.near_me = true;
                    //this.$ref.near_me.disable();
                    console.log("Geolocation is not supported by this browser.");
                }
            },
            async getGeoLocation()
            {
                let $this = this;
                $this.$store.dispatch('START_PROCESSING');
                return new Promise((resolve,reject)=> {
                    if(!$this.canGeoLocate || !("geolocation" in window.navigator ))
                    {
                        $this.$store.dispatch('STOP_PROCESSING');
                        reject(new Error("GeoLocation is  not available"));
                    }
                    window.navigator.geolocation.getCurrentPosition(pos => {
                            $this.$store.dispatch('STOP_PROCESSING');
                            resolve(pos);
                        },
                        err => {
                            $this.$store.dispatch('STOP_PROCESSING');
                            reject(err);
                        },
                        {
                            enableHighAccuracy: true,
                            maximumAge: 30000,
                            timeout: 27000
                        });
                });

            },
            async locateMe()
            {
                let $this = this;
                try
                {

                    this.myLocation = await this.getGeoLocation();
                }
                catch(err)
                {
                    if (err.name == "NotFoundError" || err.name == "DevicesNotFoundError")
                    {
                        //required track is missing
                        console.log("Device Not Found");
                        $this.$store.dispatch('GET_MESSAGES', ["Device not Found", "If this error persists, try using a different browser"]);
                    }
                    else if (err.name == "NotReadableError" || err.name == "TrackStartError")
                    {
                        //webcam or mic are already in use
                        console.log("Device Already in use");
                        $this.$store.dispatch('GET_MESSAGES', ["Device already in use by another App", "If this error persists, try using a different browser"]);
                    }
                    else if (err.name == "OverconstrainedError" || err.name == "ConstraintNotSatisfiedError", "If this error persists, try using a different browser")
                    {
                        console.log("Constraints not satisfied");
                        //constraints can not be satisfied by avb. devices
                        $this.$store.dispatch('GET_MESSAGES', ["Device unable to satisfy required constraints.", "If this error persists, try using a different browser"]);
                    }
                    else if (err.name == "NotAllowedError" || err.name == "PermissionDeniedError")
                    {
                        console.log("Permission Denied");
                        //permission denied in browser
                        $this.$store.dispatch('GET_MESSAGES', ["Browser GeoLocation Access Permission Denied", "If this error persists, try using a different browser"]);
                    }
                    else if (err.name == "TypeError" || err.name == "TypeError")
                    {
                        console.log("Constraint Error");
                        //empty constraints object
                        $this.$store.dispatch('GET_MESSAGES', ["Constraint Error"]);
                    }
                    else
                    {
                        $this.$store.dispatch('GET_MESSAGES', ["Our application is unable to access your GPS Coordinates", err.message, "If this error persists, try using a different browser"]);
                    }

                    console.log("Geo Error: " + JSON.stringify(err.message));
                }
            },

        },

        watch: {
            'location': function()
            {
                this.latlon = new Array();
                if(typeof this.location === "undefined" || this.location === null)
                {
                    this.latlon = null;
                    return;
                }



                //this.locationSelected = true;
                let _latitude, _longitude;
               for(let i = 0;i<this.location.length;i++)
               {
                   _latitude = parseFloat(this.location[i].latitude);
                   _longitude = parseFloat(this.location[i].longitude);
                   if(!isNaN(_latitude) && _latitude >= -90 && _longitude <= 90 && !isNaN(_longitude) && _longitude >= -180 && _longitude <= 180)
                   {
                       this.latlon.push({
                           position: {
                               lat: parseFloat(this.location[i].latitude),
                               lng: parseFloat(this.location[i].longitude),
                           },
                           distance: this.location[i].distance,
                           title:this.location[i].name,
                           item:this.location[i]
                       });
                   }

               }
            },
            'selectedChips':function () {
                this.formUpdated = true;
            },
            'search_input': function(_value)
            {
                if(typeof _value === "undefined" || _value === null)
                    return false;

                let $this = this;
                $this.formUpdated = true;

                if(_value.length < 2)
                    return false;

                clearTimeout(this.search_timer);
                let _ms = 500;
                this.search_timer = setTimeout(function()
                {
                    $this.searchSupplier();
                }, _ms);
            },
            'tag_search_key': function(_value)
            {
                if(typeof _value === "undefined" || _value === null)
                    return false;

                let $this = this;
                $this.formUpdated = true;

                if(_value.length < 2)
                    return false;

                clearTimeout(this.search_timer);
                let _ms = 500;
                this.search_timer = setTimeout(function()
                {
                    $this.searchTags();
                }, _ms);
            },
            'tag_selected_list': function(_value)
            {
                this.selectedChips = new Array();
                if(typeof _value !== "undefined" && _value !== null)
                {
                    let _arrChips;
                    for (let i = 0; i < _value.length; i++)
                    {
                        this.selectedChips.push(_value[i].key);
                    }
                }
            },
            'distance' : function()
            {
                this.formUpdated = true;
            },
            'near_me' : function()
            {
                this.formUpdated = true;
            },
            'discount' : function()
            {
                this.formUpdated = true;
            },
            'myLocation': function(_value)
            {
                if(typeof _value === "undefined" || _value === null)
                    return false;

                this.picked_location = null;
                this.center = {
                    lat: parseFloat(_value.coords.latitude),
                    lng: parseFloat(_value.coords.longitude)
                };

                this.searchLocation(this.center.lat,this.center.lng);
            }
        }




    };
</script>