<template>
    <div class="MapComponent"/>
</template>

<script>
    import gmapsInit from '../../utils/gmaps';


   /* const latlon = [
        {"position":
            {"lat":parseFloat(-24.659276),"lng":parseFloat(25.9023212)}
            },
        {"position":
            {"lat":parseFloat(-24.659266),"lng":parseFloat(25.902326)}
        }];*/
    export default {
        name: 'MapComponent',
        props:{
            latlon:{
                type:Array,
            },
            center:{
                type:Object,
            }
        },
        data()
        {
            return {
                instance: null,
                geocoder: null,
                map: null,
                markers: null,
                markerClickHandler: null,
                baseUrl: "",
                logoUrl: "",
                markerUrl: "geotag/homeboy_geotag_icon_blackbg_24.png",
                organisation_logo_url: "",
                defaultImage: "homeboy_banner.png",
                infoWindow: null
                //require("@/assets/img/homeboy_banner.png"),
                //markerUrl: "http://localhost:8081/geotag/homeboy_geotag_icon_blackbg_24.png"
            }
        },
        created() {
            if(typeof this.$localUrl !== "undefined" && this.$localUrl !== null && this.$localUrl.length >= 0)
            {
               this.baseUrl = this.$localUrl;
            }
            if(typeof this.$baseUrl !== "undefined" && this.$baseUrl !== null && this.$baseUrl.length >= 0)
            {
                this.logoUrl = this.$baseUrl+ "organisations/logo/";
            }
        },
        methods:{

            setInfoWindowData:function (marker,data) {
                this.infoWindow = this.getInfowindow(marker,data);
            },
          /*  imgUrlAlt() {
                $event.target.src = this.defaultImage;
            },*/
            getLogo: function(_item)
            {
                return this.logoUrl+_item.organisation;
            },
            convertDistance: function(_item)
            {
                let _distance;
                let _isKm = true;
                if(_item.distance < 1)
                {
                    _isKm = false;
                    _distance = parseFloat(_item.distance).toFixed(3) * 1000;
                }
                else
                {
                    _distance = parseFloat(_item.distance).toFixed(2);
                }


                return _distance + (_isKm?"Km":"m");
            },
            getInfowindow:function(marker,item){

                var info = '<div class="col s12 no-padding font-10 vert-offset-top-half">'+
                    '<div class="col s12 no-padding vert-offset-bottom-half font-14 homeboy-charcoal-black-text border-bottom-1 grey-border border-lighten-2">'+
                    '<div class="col s9 no-padding font-bold font-10">'+
                     item.name+
                '</div>'+
                '<div class="col s3 no-padding center-align border-left-1 grey-border border-lighten-2 font-10 font-italic font-bold">'+
                    '<span class="black-text">'+ item.discount+'% off</span>'+
                '</div>'+

                '</div>'+

                    '<div class="col s12 no-padding">'+
                    '<div class="col s12 padding-bottom-half vert-offset-bottom-half padding-top-half padding-left-0 padding-right-0 font-bold">'+
                (item.contact? /*'<div class="col s3 no-padding grey-text text-darken-2 font-9">Tel</div>'+*/
                    '<div class="col s12 black-text">'+
                        '<a href="tel:'+item.contact+'">'+
                    item.contact+
                        '</a>'+
                    '</div>'+

                    '</div>':'')+
                (item.address? /*'<div class="col s3 no-padding grey-text text-darken-2 font-9 font-bold">Address</div>'+*/
                    '<div class="col s12 homeboy-charcoal-black-text font-bold">'+
                    item.address+
                    '</div>'+

                    '</div>':'')+
                '<div class="col s12 no-padding vert-offset-top-half font-10 font-bold">'+
                    /*'<div class="col s3 no-padding grey-text text-darken-2 font-9 ">Tags</div>'+*/
                    '<div class="col s12 homeboy-charcoal-black-text">';

                for(var i=0;i<item.tags.data.length;i++)
                {
                    info+='<span class="vert-offset-right-half underline">'+
                        item.tags.data[i].name+
                        '</span>';

                }

                 info+=
                '</div>'+
                '</div><div class="col s12 no-padding vert-offset-top-half vert-offset-bottom-half">'+

                (!isNaN(parseFloat(item.distance))? /*'<div class="col s3 no-padding grey-text text-darken-2 font-9 font-bold">Distance</div>'+*/
                    '<div class="col s12 homeboy-charcoal-black-text font-bold">'+
                    '<span class="homeboy-charcoal-black-text ">'+this.convertDistance(item)+'</span> from center'+
                    '</div>'+
                    '</div>':'')+
                '</div>'+
                '</div>'+
                '</div>';

               return info;
            },
            reloadMarkers:function() {

                // Loop through markers and set map to null for each
                for (let i=0; i<this.markers.length; i++) {

                    this.markers[i].setMap(null);
                }

                // Reset the markers array
                this.markers = null;

            }
        },
        async mounted() {
            try
            {
                console.log("LatLon: "+JSON.stringify(this.latlon));
                const google = await gmapsInit();
                const geocoder = new google.maps.Geocoder();
                const map = new google.maps.Map(this.$el);
                let _selectedLocation = this.center;
                let _zoom = 16;
                console.log("CENTER: "+ JSON.stringify(this.center));
                if(typeof _selectedLocation === "undefined" || _selectedLocation === null || _selectedLocation.lat === null || _selectedLocation.lng === null)
                {
                    _zoom = 14;
                    if(this.latlon.length > 0)
                    {
                        _selectedLocation = {
                            lat: this.latlon[0].position.lat,
                            lng: this.latlon[0].position.lng,
                        };
                    }
                    else
                    {
                        _zoom = 12;
                        _selectedLocation = this.$defaultGeoCenter;
                    }
                }
                console.log("GEO: "+ JSON.stringify(_selectedLocation));

                geocoder.geocode({ location: _selectedLocation }, (results, status) => {
                    if (status !== `OK` || !results[0]) {
                        throw new Error(status);
                    }
                    map.setCenter(results[0].geometry.location);
                    map.setZoom(_zoom);
                });


                let $this = this;
                const info = new google.maps.InfoWindow({
                    maxWidth: 300,
                });
                const markerClickHandler = (marker,loc) => {

                    $this.setInfoWindowData(marker,loc);

                    map.setZoom(16);
                    map.setCenter(marker.getPosition());
                    info.setContent($this.infoWindow);
                    info.open(map,marker);

                };




                const markers = this.latlon
                    .map((location) => {
                        const marker = new google.maps.Marker(
                            {
                                position:location.position,
                                map,
                                icon:$this.baseUrl + $this.markerUrl
                               });

                        marker.addListener(`click`, () => markerClickHandler(marker,location.item));
                        return marker;
                    });

                this.instance = google;
                this.geocoder = geocoder;
                this.map = map;
                this.markers = markers;
                this.markerClickHandler = markerClickHandler;
            }
            catch (error)
            {
                // eslint-disable-next-line no-console
                console.error("MAP ERROR:"+error);
            }
        },

        watch: {
            'latlon': function(_value)
            {
                console.log("LAtLon Changed: "+JSON.stringify(_value));
                const google = this.instance;
                const map = this.map;
                let $this = this;
                const markerClickHandler = this.markerClickHandler;

                this.reloadMarkers();

                this.markers = _value.map((location) => {
                    const marker = new google.maps.Marker(
                        {
                            position:location.position,
                            map,
                            icon:$this.baseUrl + $this.markerUrl
                        });

                    marker.addListener(`click`, () => markerClickHandler(marker,location.item));
                    return marker;
                });
            }
        },

    };
</script>

<style>
    html,
    body {
        margin: 0;
        padding: 0;
    }

    .MapComponent {
        height:50vh;
        left: 0;
        right: 0;
    }
</style>