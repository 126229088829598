<template>
  <div
       class="col s12 card vert-offset-bottom-half padding-left-0 padding-right-0 padding-top-0 padding-bottom-0"
  >
    <div class="col s12 padding-top-half padding-bottom-half vert-offset-bottom-half font-12 grey lighten-3 border-bottom-1 grey-border border-lighten-2 homeboy-charcoal-black-text">
      <div class="col s9 no-padding font-bold">
        {{ item.name }}
      </div>
      <div class="col s3 no-padding center-align border-left-1 grey-border border-lighten-2 font-italic font-bold">
        <span class="hide-on-small-and-down">disc.</span>
        <span class="black-text">{{ item.discount }}%</span>
      </div>

    </div>

    <div class="col s3 m2 font-10">
      <img :src="getLogo(item)" class="responsive-img" :alt="item.name"  @error="imgUrlAlt" />
    </div>
    <div class="col s9 m10 no-padding font-10">

      <div class="col s12 padding-bottom-half vert-offset-bottom-half padding-top-half padding-left-0 padding-right-0">
        <div class="col s12 no-padding" v-show="item.contact">
          <div class="col s3 no-padding grey-text text-darken-2">
            <a :href="loadCaller(item)"
               :title="loadCallerTitle(item)"
               class="grey-text text-darken-2"
            >
              <i class="material-icons tiny">call</i>
            </a>
          </div>
          <div class="col s9 homeboy-charcoal-black-text">
            <a :href="loadCaller(item)"
               :title="loadCallerTitle(item)"
               class="homeboy-charcoal-black-text"
            >
            {{ item.contact }}
            </a>
          </div>

        </div>
        <div class="col s12 no-padding" v-if="item.address">
          <div class="col s3 no-padding grey-text text-darken-2">Address</div>
          <div class="col s9 homeboy-charcoal-black-text">{{ item.address }}</div>
        </div>
        <div class="col s12 no-padding vert-offset-top-half">
          <div class="col s3 no-padding grey-text text-darken-2">
            Tags
          </div>
          <div class="col s9 font-10">
                                        <span
                                                v-for="(_item,_index) in item.tags.data"
                                                :key="_index"
                                                class="vert-offset-right-half underline"
                                        >
                                            {{ _item.name }}
                                        </span>
          </div>
        </div>

        <div class="col s12 no-padding vert-offset-top-half" v-if="!isNaN(parseFloat(item.distance))">
          <div class="col s3 no-padding grey-text text-darken-2">
            Distance
          </div>
          <div class="col s9 homeboy-charcoal-black-text">
            <span class="homeboy-charcoal-black-text" v-html="convertDistance(item)"></span> from center
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: "SearchResultElementComponent",
  props: {
    msg: String,
      item: Object
  },
    data() {
        return {
            organisation_logo_url: "",
            defaultImage: require("@/assets/img/homeboy_banner.png"),

        }
    },
    created () {
        if(typeof this.$baseUrl !== "undefined" && this.$baseUrl !== null && this.$baseUrl.length >= 0)
        {
            this.organisation_logo_url = this.$baseUrl+ "organisations/logo/";
        }
    },
    methods : {
      loadCaller: function(_item)
      {
        return "tel:"+_item.contact;
      },
        loadCallerTitle: function(_item)
        {
            return "Call "+_item.contact;
        },
        imgUrlAlt(event) {
            event.target.src = this.defaultImage;
        },
        getLogo: function(_item)
        {
            return this.organisation_logo_url+_item.organisation;
        },
        convertDistance: function(_item)
        {
            let _distance;
            let _isKm = true;
            if(_item.distance < 1)
            {
                _isKm = false;
                _distance = parseFloat(_item.distance).toFixed(3) * 1000;
            }
            else
            {
                _distance = parseFloat(_item.distance).toFixed(2);
            }


            return _distance + (_isKm?"Km":"m");
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
